@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Stripe Element Styles */
.stripe-element-base {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    background-color: white;
    color: #1f2937;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dark .stripe-element-base {
    border-color: #4b5563;
    background-color: #374151;
    color: #f3f4f6;
}

.stripe-element-focus {
    outline: none;
    border-color: #6366f1;
    box-shadow: 0 0 0 1px #6366f1;
}

.stripe-element-invalid {
    border-color: #ef4444;
}

.dark .stripe-element-base {
    background-color: #374151 !important;
}

/* Add these styles to your global CSS file */
.overflow-x-auto {
  overflow-x: auto;
  max-height: calc(100vh - 200px); /* Adjust this value based on your layout */
}
